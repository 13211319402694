import { Geometry } from '@range.io/basic-types'
import hexToRgba from '@range.io/basic-types/src/math/hex-to-rgba.js'
import { arrayToLookupTable, mergeDeepRight, uniq } from '@range.io/functional'
import diffLookupTables from '@range.io/functional/src/ramda-like/diff-lookup-tables.js'
import { useEffect, useState } from 'react'
import { useSelector, useStore } from 'react-redux'
import { ReduxSelectors } from '../redux/index.js'

function preloadImage(imageId) {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = `/map-assets/${imageId}`
        img.onload = () => resolve(img)
        img.onerror = () => reject(new Error(`Failed to load image: ${imageId}`))
    })
}

function preloadImageFromDataURI(imageDataURI) {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = imageDataURI
        img.onload = () => resolve(img)
        img.onerror = () => reject(new Error(`Failed to load image from URI: ${imageDataURI}`))
    })
}

// For better render quality we render everything as it was X times bigger, then let the browser scale it down
const IMAGE_PIXEL_RATIO = 3.0

/*
 * Given an icon image (which has all content black) color it to be white.
 * sig colorCategoryIconImage :: (Image) -> Image
 */
const colorCategoryIconImage = async image => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    canvas.width = image.width * IMAGE_PIXEL_RATIO
    canvas.height = image.height * IMAGE_PIXEL_RATIO
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height)

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    const data = imageData.data

    for (let i = 0; i < data.length; i += 4) {
        if (data[i + 3] > 0) {
            data[i] = 255
            data[i + 1] = 255
            data[i + 2] = 255
        }
    }

    ctx.putImageData(imageData, 0, 0)
    const imageBitmap = await createImageBitmap(canvas)
    return imageBitmap
}

const ensureImageIsLoaded = async (mapboxMap, imageId, statusNames, categories) => {
    const [imageType, imageMetadata, categoryId, imageSuffix] = imageId.split('_')

    if (['photoBadge', 'taskBadge', 'photoSelectedPin', 'taskSelectedPin'].indexOf(imageType) === -1) {
        console.error('Unknown image type when loading map asset.')
        return
    }

    const isSelectedState = imageType.includes('Selected')

    const drawBackground = async () => {
        const backgroundImageAssetName = isSelectedState ? 'pin.outline+shadow@3x.svg' : 'badge.background+shadow.svg'
        const background = await preloadImage(backgroundImageAssetName)
        canvas.width = background.width * IMAGE_PIXEL_RATIO
        canvas.height = background.height * IMAGE_PIXEL_RATIO
        ctx.drawImage(background, 0, 0, canvas.width, canvas.height)
    }

    const getFillColor = () => {
        if (imageSuffix === 'archived') return '#636B83'
        if (imageSuffix === 'overdue' && !isSelectedState) return '#F13D15'
        if (imageSuffix === 'note' && !isSelectedState) return '#FF7A00'
        if (imageType === 'photoBadge' || imageType === 'photoSelectedPin') return '#F531B3'
        return statusNames[imageMetadata].color
    }

    const drawFill = async () => {
        const fillImageAsset = isSelectedState ? 'pin.fill@3x.svg' : 'badge.fill.svg'
        const fill = await preloadImage(fillImageAsset)
        const fillCanvas = document.createElement('canvas')
        fillCanvas.width = fill.width * IMAGE_PIXEL_RATIO
        fillCanvas.height = fill.height * IMAGE_PIXEL_RATIO
        const fillCtx = fillCanvas.getContext('2d')
        fillCtx.drawImage(fill, 0, 0, fillCanvas.width, fillCanvas.height)
        const imageColor = getFillColor()
        const imageColorRgba = hexToRgba(imageColor)
        const imageData = fillCtx.getImageData(0, 0, fillCanvas.width, fillCanvas.height)
        const data = imageData.data
        for (let i = 0; i < data.length; i += 4) {
            data[i] = imageColorRgba.r
            data[i + 1] = imageColorRgba.g
            data[i + 2] = imageColorRgba.b
        }
        fillCtx.putImageData(imageData, 0, 0)
        const fillImageBitmap = await createImageBitmap(fillCanvas)
        if (isSelectedState) ctx.drawImage(fillImageBitmap, 0, 0)
        else ctx.drawImage(fillImageBitmap, 3, 0)
    }

    const drawOutline = async () => {
        // we only have outline for badges
        if (isSelectedState) return
        const outline = await preloadImage('badge.outline.svg')
        ctx.drawImage(outline, 3, 0, outline.width * IMAGE_PIXEL_RATIO, outline.height * IMAGE_PIXEL_RATIO)
    }

    const drawIcon = async () => {
        const getDefaultIcon = async () => {
            const iconImageName =
                imageType === 'photoBadge' || imageType === 'photoSelectedPin'
                    ? `icon.${imageMetadata}@3x.svg`
                    : 'icon.task@3x.svg'
            const resultIcon = await preloadImage(iconImageName)
            return resultIcon
        }
        const getCategoryIcon = async () => {
            const iconImage = await preloadImageFromDataURI(categories[categoryId].icon)
            const resultIcon = await colorCategoryIconImage(iconImage)
            return resultIcon
        }

        let iconImageAsset = null
        let iconScaleFactor = IMAGE_PIXEL_RATIO
        if (imageSuffix === 'overdue' && !isSelectedState) iconImageAsset = 'icon.overdue.svg'
        if (imageSuffix === 'note' && !isSelectedState) iconImageAsset = 'icon.note@3x.svg'

        let icon = null
        if (iconImageAsset) {
            icon = await preloadImage(iconImageAsset)
        } else if (!categories[categoryId].isDefault) {
            icon = await getCategoryIcon()
            if (!isSelectedState) iconScaleFactor *= 0.8
        } else {
            icon = await getDefaultIcon()
        }

        const iconWidth = 32 * iconScaleFactor
        const iconHeight = 32 * iconScaleFactor

        const iconYOffset = isSelectedState ? -22 : -3

        ctx.drawImage(
            icon,
            (canvas.width - iconWidth) * 0.5,
            (canvas.height - iconHeight) * 0.5 + iconYOffset,
            iconWidth,
            iconHeight
        )
    }

    const drawOverlay = async () => {
        // we only have overlay for selected pins
        if (!isSelectedState) return
        // overlay exists only for note and overdue pins
        if (imageSuffix !== 'note' && imageSuffix !== 'overdue') return
        const overlayImageAsset = imageSuffix === 'note' ? 'pin.note@3x.svg' : 'pin.overdue@3x.svg'
        const overlay = await preloadImage(overlayImageAsset)
        ctx.drawImage(overlay, 0, 0, canvas.width, canvas.height)
    }

    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    await drawBackground()
    await drawFill()
    await drawOutline()
    await drawIcon()
    await drawOverlay()

    const imageBitmap = await createImageBitmap(canvas)

    if (mapboxMap.hasImage(imageId)) mapboxMap.updateImage(imageId, imageBitmap, { pixelRatio: IMAGE_PIXEL_RATIO })
    else mapboxMap.addImage(imageId, imageBitmap, { pixelRatio: IMAGE_PIXEL_RATIO })
}

/*
 * Geometry properties:
 *
 *   For all pins:
 *
 *     iconImage          String
 *     selectedIconImage  String
 *     pinType            PhotoPin|TaskPin
 *
 *   For Task Pins:
 *
 *     status             String (=== StatusName.name)
 *     statusColor        String (=== StatusName.color)
 *     text               String (=== Collaboration.name)
 *     assignee           String (from Collaboration.assignee)
 *
 * TODO: Task Pins and PhotoMarkers *both* have annotationType === 'photoMarker', which should be renamed to "pin" (?)
 * Tasks are distinguished from Photo Markers by pinType -- not by annotationType
 */

/*
 * PhotoMarkerController - manages contents of photo markers on the map.
 * Makes sure they changed based on changes in comments and uploads.
 */
const PhotoMarkerController = ({ mapboxMap, mapboxDraw }) => {
    const { getState } = useStore()

    const selectedCanvas = useSelector(ReduxSelectors.selectedCanvas)
    const allCollaborations = useSelector(ReduxSelectors.collaborationsAsObject)
    const allComments = useSelector(ReduxSelectors.commentLookupTable)
    const allUploads = useSelector(ReduxSelectors.uploadLookupTable)

    /*
     * Given a geometry of a photo marker build it's GeoJSON filling in
     * all needed properties (information about images used for selected and idle states)
     * @sig getEnrichedGeometry Geometry => GeoJSON
     */
    const getEnrichedGeometry = geometry => {
        const enrichTaskPin = () => {
            const { name, dueDate, categoryId } = collaboration
            const isOverdue = dueDate && dueDate.getTime() < Date.now()

            const getExtraSuffix = () => {
                if (isArchived) return 'archived'
                if (isOverdue) return 'overdue'
                if (hasNote && !isCompleted) return 'note'
                return 'none'
            }

            const iconImageSuffix = `${statusName.id}_${categoryId}_${getExtraSuffix()}`

            return {
                iconImage: `taskBadge_${iconImageSuffix}`,
                selectedIconImage: `taskSelectedPin_${iconImageSuffix}`,
                status: statusName.name.toUpperCase(),
                statusColor: statusName.color,
                text: name || 'Untitled Task',
                pinType: 'TaskPin',
            }
        }

        const enrichPhotoPin = () => {
            const uploads = ReduxSelectors.uploadsForCollaboration(getState(), collaboration)
            const photoCount = uploads.length

            const getMarkerCountInfix = () => (photoCount < 9 ? photoCount : '9+')

            const getExtraSuffix = () => {
                if (isArchived) return 'archived'
                if (hasNote && !isCompleted) return 'note'
                return 'none'
            }

            const iconImageSuffix = `${getMarkerCountInfix()}_${collaboration.categoryId}_${getExtraSuffix()}`

            return {
                iconImage: `photoBadge_${iconImageSuffix}`,
                selectedIconImage: `photoSelectedPin_${iconImageSuffix}`,
                pinType: 'PhotoPin',
            }
        }

        const collaboration = ReduxSelectors.firstCollaborationForGeometry(getState(), geometry.id) // may be missing
        const comments = ReduxSelectors.commentsForCollaboration(getState(), collaboration)
        const statusName = ReduxSelectors.statusNameForCollaboration(getState(), collaboration)
        const hasNote = comments.some(comment => comment.isNote && !comment.completedById)
        const isArchived = geometry.archivedDate
        const isCompleted = statusName?.isCompleted

        const geoJson = Geometry.asGeoJson(geometry)
        const properties = statusName ? enrichTaskPin() : enrichPhotoPin()
        return mergeDeepRight(geoJson, { properties })
    }

    /*
     * The geometries changed in some way; so we need to tell Mapbox Draw to redraw;
     * Uses diffLookupTables to find added, changed or removed geometries and ignores the unchanged ones
     */
    const reconcileGeometries = async () => {
        // because we call this function from a setTimeout (below), the mapboxDraw context may no longer be alive,
        // because the CanvasView has already unmounted -- which will have removed mapboxDraw from mapboxMap
        if (!mapboxMap.hasControl(mapboxDraw)) return

        // because we call this function from a setTimeout (below), geometriesForSelectedCanvas can be out of date
        const geometriesForSelectedCanvas = ReduxSelectors.geometriesForSelectedCanvas(getState())

        const enrichedGeometries = geometriesForSelectedCanvas.map(getEnrichedGeometry)

        const enrichedGeometriesLookupTable = arrayToLookupTable('id', enrichedGeometries)
        const { added, removed, changed } = diffLookupTables(
            lastEnrichedGeometriesLookupTable,
            enrichedGeometriesLookupTable
        )

        // add or remove changed geometries if there are any
        if (added.length + removed.length + changed.length > 0) {
            mapboxDraw.delete(removed)
            added.map(id => mapboxDraw.add(enrichedGeometriesLookupTable[id]))
            changed.map(id => mapboxDraw.add(enrichedGeometriesLookupTable[id])) // changed has same semantics as add
            setLastEnrichedGeometriesLookupTable(enrichedGeometriesLookupTable)
        }

        mapboxMap.fire('draw.refresh')

        const allImages = uniq(
            enrichedGeometries.flatMap(g => [g.properties.iconImage, g.properties.selectedIconImage])
        )

        allImages.forEach(imageId => ensureImageIsLoaded(mapboxMap, imageId, statusNames, categories))

        // We need to do this step to make sure Mapbox Draw notices the changes and re-renders
        enrichedGeometries.forEach(f => mapboxDraw.setFeatureProperty(f.id, 'iconImage', f.properties.iconImage))
    }

    const geometriesForSelectedCanvas = ReduxSelectors.geometriesForSelectedCanvas(getState())

    const [lastEnrichedGeometriesLookupTable, setLastEnrichedGeometriesLookupTable] = useState({})
    const statusNames = useSelector(ReduxSelectors.statusNames)
    const categories = useSelector(ReduxSelectors.categories)

    useEffect(() => {
        if (!mapboxDraw || !selectedCanvas) return

        //! HACK - for some reason we need to setTimeout this call for MapboxDraw to update on app load
        setTimeout(reconcileGeometries, 0)
    }, [
        geometriesForSelectedCanvas,
        allCollaborations,
        allComments,
        allUploads,
        selectedCanvas,
        mapboxDraw,
        statusNames,
    ])

    return null
}

export default PhotoMarkerController
